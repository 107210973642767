<template>
	<div id="login">
		<v-head></v-head>
		<div class="tabbar">
			<router-link to="/login" :class="{ 'current': isLogin }">登錄</router-link>
			<router-link to="/register" :class="{ 'current': !isLogin }">註冊</router-link>
		</div>
		<div class="form">
			<div class="form-item gender">
				<div class="gender-item" @click="clientSex == 1 ? clientSex ='' : clientSex = '1' ">
					<span :class="{'icon-gender': true, 'checked': clientSex == 1}"></span>
					<span :class="{'label-gender': true, 'checked': clientSex == 1}">男</span>
				</div>
				<div class="gender-item" @click="clientSex == 2 ? clientSex ='' : clientSex = '2' ">
					<span :class="{'icon-gender': true, 'woman': true, 'checked': clientSex == 2}"></span>
					<span :class="{'label-gender': true, 'woman': true, 'checked': clientSex == 2}">女</span>
				</div>
			</div>
            <div class="form-item">
				<img src="../../assets/imgs/mobile/icon_zhanghao@2x.png" class="icon icon-nickname">
				<input type="text" placeholder="請輸入中文姓名" v-model.trim="username">
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_dianhua@2x.png" class="icon icon-phone">
				<input type="tel" placeholder="請輸入手機號碼" @input="onPhoneInput" v-model="phone">
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_mima@2x.png" class="icon icon-password">
				<input type="password" placeholder="輸入賬號密碼" v-model="password">
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_yanzhenma@2x.png" class="icon icon-phoneCode">
				<input type="number" placeholder="輸入短信驗證碼" v-model="phoneCode">
                <div :class="{'get-phoneCode': true, 'active': phonecodeVisible}" @click="getPhoneCode">{{phoneCodeText}}</div>
			</div>
            <div @click="onCheckboxChange" :class="{'agree-protocol': true, 'checked': signedPrivacy}">
                <div class="my-checkbox"></div>
                <div>我已閱讀並接受本站<router-link to='/home/privacy'>隱私條款</router-link>和<router-link to="/home/disclaimer">免責聲明</router-link></div>
            </div>
			<div class="btn-register" @click="doRegister"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在註冊...' : '免費註冊'}}</div>
		</div>
	</div>
</template>

<script>
	
	import vHead from '@/layouts/MobileHead.vue'
	
	export default {
		data() {
			return {
				loading: false,
                isLogin: true,
				signedPrivacy: false,
				phonecodeVisible: false,
				username: '',
				phone: '',
				password: '',
				phoneCode: '',
				timeCount: -1,
				timer: null,
				codeKey: '',
				phoneCodeText: '獲取驗證碼',
				clientSex: '',
			}
		},
		components: {
			vHead
		},
		mounted: function() {
			if(this.$route.path == '/register') {
				this.isLogin = false
			}
        },
        methods: {
            onCheckboxChange: function(){
                this.signedPrivacy = !this.signedPrivacy
			},
			onPhoneInput: function(){
				if(/^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
					this.phonecodeVisible = true
				} else {
					this.phonecodeVisible = false
				}
			},
			getPhoneCode: function(){
				if(this.timeCount != -1) return
				if(! (/^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phone.trim()))) {
					this._toast.warning(this, '請輸入手機號')
					return
				}
				
				this.$store.dispatch('getVCode',{codeType: 1, phoneNumber: this.phone})
				.then((rs) => {
					this.codeKey = rs.codeKey
					this._toast.success(this, '驗證碼已經發送')
					
					this.timeCount = 90
					this.phoneCodeText = `${this.timeCount}秒後可重發`

					this.timer && clearInterval(this.timer)
					this.timer = setInterval(() => {
						this.timeCount --
						if(this.timeCount < 0) {
							this.phoneCodeText = '獲取驗證碼'
							this.timer && clearInterval(this.timer)
						} else {
							this.phoneCodeText = `${this.timeCount}秒後可重發`
						}
					}, 1000);
				}).catch((msg) => {
					this.timeCount = -1
					this._toast.warning(this, msg || '操作失敗，請稍候重試')
				})
			},
			doRegister: function(){
				if(this.loading) return
				
				if(!this.clientSex) {
                    this._toast.warning(this, '請選擇性別')
                    return
				}
                if(!this.username) {
                    this._toast.warning(this, '請輸入中文姓名')
                    return
                }
				if(! this.phone.trim()){
					this._toast.warning(this, '請輸入手機號')
					return
				}
				if(! /^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) && ! /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
					this._toast.warning(this, '手機號格式不正確')
					return
				}
				if(! this.password) {
					this._toast.warning(this, '請輸入密碼')
					return
				}
				if(this.password.length < 6) {
					this._toast.warning(this, '密碼長度不能小於6位')
					return
				}
				if(!this.phoneCode) {
					this._toast.warning(this, '請輸入短信驗證碼')
					return
				}
				if(parseInt(this.phoneCode) != this.phoneCode || this.phoneCode.length != 6) {
					this._toast.warning(this, '驗證碼輸入不正確')
					return
				}
				if(! this.signedPrivacy) {
					this._toast.warning(this, '请勾选’我已閱讀並接受本站隱私條款和免責聲明‘')
					return
				}
				
				this.loading = true
				var params = {
                    clientName: this.username,
                    codeKey: this.codeKey,
                    confirmPassword: this.password,
                    password: this.password,
                    phoneCode: this.phoneCode,
                    phoneNumber: this.phone.trim(),
                    signedPrivacy: this.signedPrivacy,
                    sourceType: 'F',
                    clientSex: this.clientSex
                }
				this.$store.dispatch('register', params).then(() => {
					this._toast.success(this, '註冊成功')
                    this.$store.dispatch('login', {
                        loginName: this.phone.trim(),
                        password: this.password
                    }).then(() => {
                        this.$router.push({path: '/'}); //登录成功之后重定向到首页
                        this.loading = false
                    }).catch((rs) => {
                        this.$router.push({path: '/login'});
                        this.loading = false
                    })
                }).catch((error) => {
                    this.loading = false
					this._toast.warning(this, error || '操作失敗')
                })
			}
        }
	}
</script>

<style lang="scss" scoped>
	#login {
		padding-top: 83px;
		width: 100%;
		height: 100%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.tabbar {
		display: flex;
		flex-direction: row;
		height: 54px;
		width: 260px;
		justify-content: space-between;
		align-items: center;
		margin-top: 91px;
		margin-bottom: 80px;
		position: relative;
	}
	
	.tabbar > a {
		display: inline-block;
		height: 54px;
		color: #231F20;
		font-weight: bold;
		font-size: 36px;
		line-height: 34px;
	}

	.tabbar > a.current {
		color: #36C4D0;
		position: relative;
	}

	.tabbar > a.current::after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 14px;
		height: 6px;
		background-color: #36C4D0;
		transform: translateX(-50%);
	}

	.tabbar::after {
		content: '';
		position: absolute;
		left: 50%;
		top: 9px;
		transform: translateX(-50%);
		width: 1px;
		height: 16px;
		background-color: #DDDDDD;
	}
	
	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form > .form-item {
		display: flex;
		flex-direction: row;
		height: 88px;
		width: 540px;
		border-bottom: 1px solid #E8E8E8;
		align-items: center;
		font-weight: bold;
		color: #231F20;
		font-size: 28px;
        position: relative;
	}

	.form > .form-item:hover {
		border-color: #7BD5DF;
	}

	 .form > .form-item.gender {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .form > .form-item.gender > .gender-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 35px;
        cursor: pointer;
        user-select: none;
    }

    .form > .form-item.gender > .gender-item > .label-gender {
        font-size: 28px;
        color: #969696;
        font-weight: 400;
    }

    .form > .form-item.gender > .gender-item > .label-gender.checked {
        font-weight: bold;
        color: #1998f2;
    }

    .form > .form-item.gender > .gender-item > .label-gender.woman.checked {
        color: #f45544;
    }

    .form > .form-item.gender > .gender-item > .icon-gender {
        margin-right: 4px;
        width: 45px;
        height: 45px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/mobile/ic_man_normal.png);
    }

    .form > .form-item.gender > .gender-item > .icon-gender.woman {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/mobile/ic_woman_normal.png);
    }

    .form > .form-item.gender > .gender-item > .icon-gender.checked {
        background-image: url(../../assets/imgs/mobile/ic_man_checked.png);
    }

    .form > .form-item.gender > .gender-item > .icon-gender.woman.checked {
        background-image: url(../../assets/imgs/mobile/ic_woman_checked.png);
    }
	
	.form > .form-item > input::placeholder {
		font-size: 24px;
		font-weight: 400;
		color: #969696;
	}

	.form > .form-item > .icon {
		width: 16px;
		height: 16px;
		margin: 0px 18px 0px 12px;
	}

    .form > .form-item > .get-phoneCode {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 160px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #969696;
        text-align: center;
        line-height: 48px;
        font-size: 24px;
        font-weight: 400;
        color: #969696;
    }

    .form > .form-item > .get-phoneCode.active {
        color: #FF8000;
        border-color: #FF8000;
    }

	.form > .btn-login {
		box-shadow: 0px 10px 25px 0px #9EE3E8;
		width: 256px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		color: #fff;
		font-size: 34px;
		font-weight: bold;
		border-radius: 40px;
		background-color: #36C4D0;
		margin-top: 210px;
		user-select: none;
	}

	.form > .btn-login:active {
		box-shadow: 0px 5px 15px 0px #9EE3E8;
	}

	.form > .forget-password {
		margin-top: 142px;
		font-weight: 400;
		font-size: 28px;
		color: #636363;
		line-height: 28px;
		text-decoration: underline;
	}

    .form > .agree-protocol {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 25px;
        width: 100%;
        align-items: center;
        margin-top: 91px;
        font-size: 24px;
        color: #969696;
        font-weight: 400;
    }
    
    .form > .agree-protocol > .my-checkbox {
        width: 24px;
        height: 24px;
        border: 1px solid #969696;
        margin-right: 8px;
        box-sizing: border-box;
    }

    .form > .agree-protocol.checked > .my-checkbox {
        position: relative;
    }

    .form > .agree-protocol.checked > .my-checkbox::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 26.7px;
        width: 21.9px;
        background-image: url(../../assets/imgs/mobile/img_goux@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .form > .agree-protocol a {
        color: #231F20;
        text-decoration: underline;
    }

    .form > .btn-register {
		box-shadow: 0px 10px 25px 0px #9EE3E8;
		width: 80%;
		height: 80px;
		color: #fff;
		font-size: 34px;
		font-weight: bold;
		border-radius: 40px;
		background-color: #36C4D0;
		margin-top: 130px;
		user-select: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

    .form > .btn-register:active {
		box-shadow: 0px 5px 15px 0px #9EE3E8;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        margin-top: 14px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */

</style>
